@import "~antd/dist/antd.css";
@import url("http://fonts.cdnfonts.com/css/sf-pro-display");
@font-face {
  font-family: TuskerGrotesk;
  src: url("./fonts/TuskerGrotesk-webfont.woff2"),
    url("./fonts/TuskerGrotesk-webfont.woff2") format("woff2");
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: auto;
}
#root {
  height: 100%;
  width: 100%;
}
body {
  background-color: #f8f9fa !important;
  font-family: "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.ant-layout-sider .ant-layout-sider-light {
  flex: 0 0 300px;
  max-width: 300px;
  width: 300px;
  min-width: 300px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*.ant-input:focus {
  border-color: #587bf8;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px #587bf8;
  box-shadow: 0 0 0 2px #587bf8;
}

.ant-input-affix-wrapper:focus-within {
  border-color: #587bf8;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px #587bf8;
  box-shadow: 0 0 0 2px #587bf8;
}
*/
.rotatePhone {
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.transparent > div {
  background-color: transparent !important;
}

.transparent .ant-input-affix-wrapper > input {
  background-color: transparent !important;
  color: white;
}

.transparent .ant-input-prefix {
  color: white;
}

.transparent .ant-input-suffix > span {
  color: white;
}

.ant-select-selector {
  border-radius: 20px !important;
}

.walletType > .ant-select-selector {
  border-radius: 10px !important;
  background: transparent !important;
  color: white !important;
}

.walletType > .ant-select-selector > span {
  background: transparent !important;
  color: white !important;
}

.walletType > .ant-select-selector > div {
  background: transparent !important;
  color: white !important;
}

.transparent .ant-input-affix-wrapper {
  background-color: transparent !important;
}

.ant-modal-close-x {
  color: white;
  font-size: 30px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.walletSelect > div {
  border-radius: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
